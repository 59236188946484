import { styled } from '@linaria/react';

import { MEDIA, TEXT, COLOR, TRANSITION } from 'theme/vars';

export const Wrapper = styled.div`
  display: grid;
  gap: 24px;

  @media (min-width: ${MEDIA.TABLET}) {
    gap: 36px;
  }
`;

export const Items = styled.div`
  display: grid;
  gap: 12px;

  @media (min-width: ${MEDIA.DESKTOP}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Item = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 8px;

  ${TEXT.SEMI_BOLD};
  color: var(${COLOR.N_PRIMARY});
  background: var(${COLOR.B_SECONDARY_200});
  border-radius: 12px;
  transition: var(${TRANSITION});

  @media (min-width: ${MEDIA.TABLET}) {
    padding: 12px;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(${COLOR.C_PRIMARY});
  }
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 12px;

  color: var(${COLOR.N_PRIMARY});
  background: var(${COLOR.O_DEPARTMENTS});
  border-radius: 8px;
  font-family: -apple-system, Averta, 'Roboto', 'Helvetica Neue', 'Open Sans',
    'PT Sans', 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 14px;
  letter-spacing: -0.1px;

  @media (min-width: ${MEDIA.TABLET}) {
    width: 32px;
    height: 32px;
    margin-right: 16px;

    border-radius: 8px;
    font-size: 12px;
  }
`;
