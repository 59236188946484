import * as React from 'react';
import { Helmet } from 'react-helmet';

import { Main, Features, Documentation, Price } from './screens';
import { Wrapper, PseudoLogo } from './styled';

const About = () => (
  <React.Fragment>
    <Helmet>
      <title>About SingleDesk</title>

      <meta name='description' content='Description' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
    </Helmet>

    <Wrapper>
      <Main />

      <Features />

      <Documentation />

      <Price />

      <PseudoLogo />
    </Wrapper>
  </React.Fragment>
);

export default About;
