import * as React from 'react';

import { SectionWrapper } from 'components/Layout';
import GetAccessButton from 'components/GetAccessButton';

import { Wrapper, Form, Input, Description } from './styled';

const Price = () => {
  const [phoneNumber, setPhoneNumber] = React.useState('');

  return (
    <SectionWrapper>
      <Wrapper>
        <h3>{'Информация о стоимости использования продукта'}</h3>

        <Description>
          Стоимость использования ПО рассчитывается индивидуально. Для получения
          информации о ценах и вариантах установки, пожалуйста, свяжитесь с
          нами!
        </Description>

        <Form>
          <Input
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            placeholder='Телефон'
          />

          <GetAccessButton disabled={!phoneNumber} />
        </Form>
      </Wrapper>
    </SectionWrapper>
  );
};

export default Price;
