import { styled } from '@linaria/react';

import { MEDIA } from 'theme/vars';

export const Wrapper = styled.div`
  display: grid;
  gap: 24px;

  @media (min-width: ${MEDIA.TABLET}) {
    gap: 36px;
  }
`;

export const Items = styled.div`
  display: grid;
  gap: 24px;

  @media (min-width: ${MEDIA.TABLET}) {
    gap: 32px;
  }

  @media (min-width: ${MEDIA.DESKTOP}) {
    gap: 28px 60px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const IconWrapper = styled.div`
  width: 24px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 12px;

  @media (min-width: ${MEDIA.TABLET}) {
    width: 40px;
    margin-right: 16px;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;
