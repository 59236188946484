import { styled } from '@linaria/react';

import { MEDIA, COLOR, TEXT } from 'theme/vars';

export const Wrapper = styled.div`
  display: grid;
  gap: 24px;
  padding-bottom: 80px;

  @media (min-width: ${MEDIA.TABLET}) {
    gap: 40px;
  }
`;

export const Description = styled.p`
  max-width: 640px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  @media (min-width: ${MEDIA.TABLET}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Input = styled.input`
  flex-grow: 1;
  max-width: 300px;
  padding: 0 0 8px 0;
  margin-bottom: 36px;

  ${TEXT.SEMI_BOLD};
  color: var(${COLOR.N_SECONDARY_300});
  border-bottom: 1px solid var(${COLOR.N_SECONDARY_300});
  background: none;

  @media (min-width: ${MEDIA.TABLET}) {
    margin-bottom: 0;
  }
`;
