import * as React from 'react';

import { SectionWrapper } from 'components/Layout';

import { Wrapper } from './styled';

const Main = () => (
  <SectionWrapper>
    <Wrapper>
      <h1>О продукте</h1>

      <p>
        {
          'Платформа Single Desk предназначена для предприятий с большим количеством сотрудников, интенсивно использующим документооборот и деловое общение в повседневной деятельности. Установка платформы повышает эффективность использования автоматизированных рабочих мест, улучшает коммуникацию и упрощает решение деловых задач.'
        }
      </p>
    </Wrapper>
  </SectionWrapper>
);

export default Main;
