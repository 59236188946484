import * as React from 'react';

import { SectionWrapper } from 'components/Layout';

import { Wrapper, Items, Item, IconWrapper } from './styled';

const items = [
  { link: '#', title: 'Общее описание системы' },
  { link: '#', title: 'Руководство по установке' },
  { link: '#', title: 'Описание функциональных характеристик' },
  { link: '#', title: 'Руководство оператора' },
  { link: '#', title: 'Описание жизненного цикла ПО' },
  { link: '#', title: 'Свидетельство о регистрации программы для ЭВМ' },
] as const;

const Documentation = () => (
  <SectionWrapper>
    <Wrapper>
      <h3>Документация</h3>

      <Items>
        {items.map(({ title, link }) => (
          <Item
            key={title}
            href={link}
            target='_blank'
            rel='noopener noreferrer'
          >
            <IconWrapper>PDF</IconWrapper>

            <p>{title}</p>
          </Item>
        ))}
      </Items>
    </Wrapper>
  </SectionWrapper>
);

export default Documentation;
