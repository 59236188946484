import { styled } from '@linaria/react';

import { MEDIA } from 'theme/vars';
import { LogoOutlined } from 'theme/icons/logo';

export const Wrapper = styled.main`
  position: relative;
`;

export const PseudoLogo = styled(LogoOutlined)`
  position: absolute;
  z-index: -1;
  top: -250px;
  right: 10px;

  width: 240px;
  height: 240px;
  flex-shrink: 0;

  @media (min-width: ${MEDIA.TABLET}) {
    top: -250px;

    width: 444px;
    height: 444px;
  }

  @media (min-width: ${MEDIA.DESKTOP}) {
    top: -400px;

    width: 780px;
    height: 780px;
  }
`;
