import * as React from 'react';

import { Workspaces, Cogs, Contact, Hierarchy, Phone } from 'theme/icons/40';

import { SectionWrapper } from 'components/Layout';

import { Wrapper, Items, Item, IconWrapper } from './styled';

const items = [
  {
    id: 'workspaces',
    description:
      'Создавать изолированные рабочие пространства пользователей с гибким управлением правами доступа и единой точкой входа в информационные системы предприятия.',
    icon: Workspaces,
  },
  {
    id: 'device',
    description:
      'Предоставлять пользователям контролируемый доступ к рабочим пространствам с мобильных устройств в любой точке и в любое время.',
    icon: Phone,
  },
  {
    id: 'hierarchy',
    description:
      'Создавать группы пользователей в соответствии с иерархией и структурой бизнес-процессов.',
    icon: Hierarchy,
  },
  {
    id: 'contact',
    description:
      'Настраивать и использовать книгу контактов в соответствии с оргструктурой компании: отделы, должности, иерархия, проекты, замещающие контакты и др.',
    icon: Contact,
  },
  {
    id: 'cogs',
    description:
      'Использовать в качестве эффективного средства управления процессами мессенджер платформы с выстраиванием ветвей производственного чата для управления ведущимися проектами. ',
    icon: Cogs,
  },
] as const;

const Features = () => (
  <SectionWrapper>
    <Wrapper>
      <h3>Платформа Single Desk позволяет:</h3>

      <Items>
        {items.map(({ id, description, icon: Icon }) => (
          <Item key={id}>
            <IconWrapper>
              <Icon />
            </IconWrapper>

            <p>{description}</p>
          </Item>
        ))}
      </Items>
    </Wrapper>
  </SectionWrapper>
);

export default Features;
