import { styled } from '@linaria/react';

import { MEDIA } from 'theme/vars';

export const Wrapper = styled.div`
  display: grid;
  gap: 24px;
  margin-top: 60px;

  @media (min-width: ${MEDIA.TABLET}) {
    gap: 40px;
    max-width: 640px;
  }
`;
